import classes from './Section.module.css';
import { useEffect, useState} from 'react';
import '../Form/FormStyles.css';
import React from 'react';
import Select from 'react-select';


const Section = (props) => {

    useEffect(() => {
        console.log("Updates Props: ",props);

    }, [props]);

    const checkIfSectionDisabled = (field, fieldKey) => {
        let selectionDisabled = true;
        if(props.dropdownOptions[fieldKey]!==undefined ){
            if((field.type === 'selection' || field.type === 'dropdown') &&  props.dropdownOptions[fieldKey].length>1 ){
                selectionDisabled = false;
            }
            if((field.type === 'selection' || field.type === 'dropdown') && props.dropdownOptions[fieldKey][0].label!=='' && props.dropdownOptions[fieldKey][0].value!=='' ){
                selectionDisabled =  false;   
            }
        }
        
        return selectionDisabled;

    }

    

    return(
        <div key={props.sectionIndex} id={`section-${props.sectionIndex}`} className={classes.FormSection}>
        <h2 className={classes.FormSectionTitle}>{props.section.title}</h2>
        {props.section.fields.map(field => {
            const fieldKey = `${props.sectionIndex}-${field.name}`;
            const selectedOption = props.dropdownOptions[fieldKey]?.find(option => String(option.value).trim() === String(props.fieldValues[fieldKey]).trim());
            console.log("fieldKey: ", fieldKey);
            console.log("selectedOption: ", selectedOption); // Check what's being set as the selected option
            console.log("dropdownOptions: ", props.dropdownOptions[fieldKey]); // Check the structure and data of dropdown options
            console.log("value: ", props.fieldValues[fieldKey]); // Check the current value set in fieldValues for this dropdown
            console.log("fieldType: ", field.type);

            // if(props.dropdownOptions[fieldKey]!==undefined){

            //     props.dropdownOptions[fieldKey].forEach(element => {
            //         if(element.value === undefined){
            //             element.value  = element.label;
            //         }
            //     });
            // }

            // Example of constructing a dependency field key
            //const dependencyFieldKey = `${dependencySectionIndex}-${dependencyFieldName}`;


            // const dependencyFieldName = field.api.depFieldKey ? field.api.depFieldKey : '';
            // //const dependencySecIndex  = field.api.sectionName;
            // const depSection = formConfig.sections.find(sec => sec.title === field.api.sectionName);
            // const depSectionIndex = formConfig.sections.indexOf(depSection);
            // const depSelection = fieldValues[`${depSectionIndex}-${dependencyFieldName}`]
            // const selectionEnabled = depSelection !== undefined;

            let selectionDisabled = checkIfSectionDisabled(field, fieldKey);
            console.log("selectionDisabled: ", selectionDisabled);

            return (
                <><div key={field.name} className={classes.FormFieldRow} style={{ display: props.determineVisibility(field, props.sectionIndex) ? 'flex' : 'none', alignItems: 'center' }}>                 
                    <label className={classes.FormLabel}>
                        {field.label}
                        {field.required && <span className={classes.Mandatory}>*</span>}
                    </label>
                    <div className={classes.InputContainer}>
                        {field.type === 'textarea' ? (
                            <textarea
                                name={field.name}
                                required={field.required}
                                maxLength={field.maxLength}
                                placeholder={field.placeholder}
                                onChange={(e) => props.handleNativeInputChange(e, props.sectionIndex)}
                                value={props.fieldValues[fieldKey] || ''}
                                className={classes.FormField} />
                        ) : field.type === 'selection' ? (
                            props.dropdownOptions[fieldKey] && props.dropdownOptions[fieldKey].length > 0 ? (
                                <Select
                                    options={props.dropdownOptions[fieldKey]}
                                    onChange={(selectedOption) => props.handleSelectChange(selectedOption, props.sectionIndex, field.name)}   
                                    value={props.dropdownOptions[fieldKey].find(option => option.value === props.fieldValues[fieldKey])}
                                    isDisabled={selectionDisabled}  // Disable if dependency is not met
                                    className={classes.FormField}
                                    styles={props.customStyles}
                                    placeholder={field.placeholdertext || 'Select...'}
                                />
                            ) : (
                                <p>Loading options...</p> // Or some other placeholder content
                            )     
                        ) : field.type === 'dropdown' ? (
                            <select
                                name={field.name}
                                onChange={(e) => props.handleNativeInputChange(e, props.sectionIndex)}
                                value={props.fieldValues[fieldKey] || ''}
                                className={classes.FormField}
                                disabled={selectionDisabled}  // Disable if dependency is not met
                            >
                                {props.dropdownOptions[fieldKey]?.map(option => (
                                    <option key={option.value} value={option.value}>{option.label}</option>
                                ))}
                            </select>
                        ) : (
                            <input
                                type={field.type}
                                name={field.name}
                                maxLength={field.length}
                                required={field.required}
                                placeholder={field.placeholdertext}
                                onChange={(e) => props.handleNativeInputChange(e, props.sectionIndex)}
                                value={props.fieldValues[fieldKey] || ''}
                                className={classes.FormField} />
                        )}
                    </div>
                </div>
                <div className={classes.FormFieldRow} >
                <label className="form-label"> </label>
                    {props.errors[fieldKey] && <div className="error-message">{props.errors[fieldKey]}</div>}
                </div>    
                <div className={classes.FormFieldRow} >
                <label className="form-label"> </label>
                <div className="input-container">
                        {selectedOption && selectedOption.additionalText && (
                            <p className="additional-text">{selectedOption.additionalText}</p>
                        )}
                </div>
                </div></>
            );
        })}
    </div>
    )
}

// export default React.memo(Section);

export default Section;