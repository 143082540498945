import React, { useState, useEffect } from 'react';
import apiGatewayService from '../../common/apiGateway.service';
import classes from './Attachments.module.css';
import Spinner from '../Spinner/Spinner';
import localStorageService from '../../common/localStorage.service';

const Attachments = (props) => {
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [cogntioUser, setCogntioUser] = useState(null);
  
  useEffect(() => {
    const user = localStorageService.getObject('user');
    setCogntioUser(user);
    console.log("currentCognito User: ", user);
}, []);

  const handleFileChange = (e) => {
    const selectedFiles = e.target.files['0'];
    files.push(selectedFiles);
    setFiles(Array.from(files));
    console.log(files);
  };

  const handleSubmit = async () => {
    console.log('Files to upload:', files);
    generatePresignedUrls();
  };

  const generatePresignedUrls = async () => {
    const keys = files.map((file, index)=>{
        console.log(file);
        console.log(index);
        return {
            index: index,
            key: file.name,
        }
    });
    try {
    //-------------------Fetching token and email for pre signed up urls---------------
    const userGroup = cogntioUser.signInUserSession.accessToken.payload["cognito:groups"];
    const token = cogntioUser.signInUserSession.idToken.jwtToken;
    const email = cogntioUser.username;
    console.log("token: ", token);
    console.log("email: ", email);
    console.log("userGroup: ", userGroup);
    //---------------------------------------------------------------------------------
    const postBody = {
      keys: keys,
      userGroupName: userGroup[0],
      email: email
    };
    console.log(postBody);
    setLoading(true);
    const response = await apiGatewayService.postBWAnalyzerFiles(token,postBody);
    console.log(response);
    setLoading(false);
    const indexAndUrls = response.data;
    for(const index of Object.keys(indexAndUrls)){
        await fetch(indexAndUrls[index], {
            method: 'PUT',
            body: files[index]
          });
        console.log("upload this file: ", files[index])
    };
    setFiles([]);
    props.setIsUploadFiles(false);
    alert("Your files are being processed.");
    } catch(error){
      setLoading(false);
      console.log("Could not upload file due to error: ", error);
      alert("Can not submit files please refresh and try again");
    }

  };

  const removeFile = (indexInput) => {
    const newFiles = files.filter((file, index)=> index!==indexInput);
    console.log(newFiles);
    setFiles(Array.from(newFiles));
  };

  return (
    <div className={classes.MainDiv}>
      <h2> Upload Files </h2> 
      <div className={classes.SecondDiv}>
      <input className={classes.InputFile} type="file" multiple onChange={handleFileChange}  placeholder="Select Files" />
      <div>
        <h3>Attached Files:</h3>
        <ul>
          {files.map((file, index) => (
            <div className={classes.FilesDiv}>
            <button className={classes.RemoveFileButton} onClick={() => removeFile(index)}>&#10005;</button>
            <li className = {classes.File} key={index}>{file.name}</li>
            </div>
          ))}
        </ul>
      </div>
      </div>
      <button className={classes.UploadButton} onClick={handleSubmit}>Submit</button>
      <div>
            {loading && <Spinner/>}
      </div> 
    </div>
  );
};

export default Attachments;
