import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import classes from './SignIn.module.css';
import Header from '../Header/Header';
import Error from '../Error/Error';
import Spinner from '../Spinner/Spinner';
import logoBlack from  '../../assets/images/citra-header-logo.png';
import localStorageService from '../../common/localStorage.service';

const SignIn = ({ auth }) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [isValid, setIsValid] = useState(false);
  const [error, setError] = useState('');
  const [errorSignUp, setErrorSignUp] = useState('');
  const [loading, setLoading] = useState(false);
  const [clicked, setClicked] = useState(false);

  // useEffect(() => {
  //   let debounceTimer;
  //   if (clicked) {
  //     // Set a timeout to reset the clicked state after 1 second
  //     debounceTimer = setTimeout(() => {
  //       setClicked(false);
  //     }, 10000); // Adjust the debounce time as needed (e.g., 1000ms = 1 second)
  //   }

  //   return () => {
  //     // Clear the timeout when the component unmounts or when clicked changes
  //     clearTimeout(debounceTimer);
  //   };
  // }, [clicked]);
  
  const setAllErrorsToEmpty = () =>{
    setError('');
    setErrorSignUp('');
  }

  const validateEmail = () => {
    if(email===''){
      setIsValid(false);
      return false;
    }
    const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValidEmail = pattern.test(email);
    setIsValid(isValidEmail);
    return isValidEmail;

  };

  const handleSignUp = async () => {
    try {
      setLoading(true);
      await auth.signUp(email);
      console.log('User signed up successfully');
      await auth.signIn(email);
      setLoading(false);
      console.log('User signed in successfully');
      navigate('/enter-secret-code');
    }
    catch (error) {
      setLoading(false);
      if (error.name === 'UserNotConfirmedException' || error.name === 'InvalidLambdaResponseException') {
        setErrorSignUp("Email domain not recognised, Please contact Citra support for email registration");
        console.log(errorSignUp);
        console.log(error.name);
      }
      else {
        setErrorSignUp("Error in sign up.Please contact Citra support for email registration");
      }
      console.error('Error signing up:', error);
    }
  }

  const handleSignIn = async () => {
    console.log("clicked: ",clicked);
    if(!clicked){
      console.log('Button clicked!');
      setClicked(true);
      setAllErrorsToEmpty();
      try {
        setLoading(true);
        const response = await auth.signIn(email);
        setLoading(false);
        console.log('User signed in successfully');
        console.log(response);
        localStorageService.setObject('signIn', 'signIn');
        navigate('/enter-secret-code');
        // navigate('/bw-analyser');
      } catch (error) {
        setLoading(false);
        if (error.name === 'UserNotFoundException') {
          console.log("User not found. Trying to sign up the user");
          await handleSignUp();
          setClicked(false);
        }
        console.error('Error signing in:', error);
      }
    }
    else{
      console.log('Button is clicked already. Waiting for response.')
    }

  };

  const validateEmailAndSignIn = async () => {
    const checkValid = validateEmail();
    setIsValid(checkValid);
    console.log(isValid);
    if(checkValid){
      setError('');
      handleSignIn();
    }
    else{
      setError('Please enter a valid email');
    }

  };

  const returnToMainPage = () => {
    navigate('/bw-analyser');
  };

  return (  
    <div className={classes.MainBackground}>
    <div className={classes.SignIn}>
      <Header />
      <div className={classes.Border}>
      <div className={classes.LogoDiv}>
                <img className={classes.Logo} src={logoBlack} alt="ao-logo"/>
      </div>
      <hr></hr>
        <h3 className={classes.Header}>Enter your email address to continue</h3>
        <input
          type="email"
          placeholder="Enter Your Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className={classes.InputClass}
        />
        <button className={classes.Button} onClick={validateEmailAndSignIn}>Sign In</button>
        <button className={classes.Button} onClick={returnToMainPage}>Return</button>
      </div>
      {loading && <Spinner/>}
      <Error error={error}></Error>
      <Error error={errorSignUp}></Error>
    </div>
    </div>
  );
};


export default SignIn;
