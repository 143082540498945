import { AWS_API, userPoolClientId, userPoolId } from "../globalVariables";
import axios from "axios";

class ApiGatewayService {

    async getFormData(token,email){
        console.log(token, email);
        const config = {
            headers: {authorization: token, appclientid: userPoolClientId, userpoolid: userPoolId},
            params: { email: email }
        }
        const response = await axios.get(AWS_API + '/get-form-details/data', config);
        return response;
    }

    // async postFormData(token,postDataInput){
    //     console.log(token, postDataInput);
    //     const headers = {
    //         headers: {authorization: token, appclientid: userPoolClientId, userpoolid: userPoolId},
    //     }
    //     const postData = {
    //         postData: postDataInput,
    //     }
    //     console.log('postdata: ', postData)
    //     const response = await axios.post(AWS_API + '/post-form-details/data', postData, headers);
    //     return response;
    // }

    async getFormConfiguration(token, formIdFromUrl) {
        console.log("formIdFromUrl: ",formIdFromUrl);
        console.log("token: ", token);
        const config = {
            headers: {authorization: token, appclientid: userPoolClientId, userpoolid: userPoolId},
            // params: { email: email }
        }
        const response = await axios.get(`${AWS_API}/form/${'register'}`, config);
        return response;
    }

    async getDropDownData(token, dropDownUrl) {
        console.log("dropDownUrl: ", dropDownUrl);
        console.log("tokenDropdown: ", token);
        const config = {
            headers: {authorization: token, appclientid: userPoolClientId, userpoolid: userPoolId},
            // params: { email: email }
        }
        const response = await axios.get(dropDownUrl, config);
        return response;
    };

    
    async postFormData(token, submitApi, postData) {
        // we have to generate proper headers with tokens for this api call later on
        console.log("Posting options: ", postData);
        const postBody = {
            postData,
        };
        const config = {
            headers: {authorization: token, appclientid: userPoolClientId, userpoolid: userPoolId},
            // params: { email: email }
        }
        const response = await axios.post(submitApi, postBody, config);
        console.log("response for post options: ", response);
        return response;
    };

    async getBWAnalyzerFiles(token) {
        const config = {
            headers: {authorization: token, appclientid: userPoolClientId, userpoolid: userPoolId},
            // params: { email: email }
        }
        const response = await axios.get(`${AWS_API}/files/1`, config);
        return response;
    };

    async postBWAnalyzerFiles(token,postData) {
        const postBody = {
            postData,
        }
        const config = {
            headers: {authorization: token, appclientid: userPoolClientId, userpoolid: userPoolId},
            // params: { email: email }
        }
        const response = await axios.post(`${AWS_API}/files/1`, postBody, config);
        return response;
    }





}

const apiGatewayService = new ApiGatewayService();
export default apiGatewayService;
