import React from 'react';
import { BrowserRouter as Router, Route, Navigate, Routes } from 'react-router-dom';

// Import your components
import SignUp from './modules/SignUp/SignUp';
import SignIn from './modules/SignIn/SignIn';
import SignOut from './modules/SignOut';
import VerifyCode from './modules/VerifyCode/VerifyCode';
import UserDetails from './modules/UserDetails';
import authService from './common/auth.service';
import RegistrationForm from './modules/RegistrationForm/RegistrationForm';
import ThankYou from './modules/ThankYou/ThankYou';
import Form from './modules/Form/Form';
import BWAnalyzer from './modules/BWAnalyser/BWAnalyser';
import Attachments from './modules/Attachments/Attachments';
import Blurb from './modules/Blurb/Blurb';

// Define your main App component
const App = () => {
  const auth = authService;
  console.log(auth);

  return (
    <Router>
      <Routes>
        <Route path="/sign-in" element={<SignIn auth={auth} />} />
        <Route path="/sign-up" element={<SignUp auth={auth} />} />
        <Route path="/enter-secret-code" element={<VerifyCode auth={auth} />} />
        <Route path="/registration-form" element={<RegistrationForm auth={auth} />} />
        <Route path="/thank-you" element={<ThankYou auth={auth} />} />
        {/* <Route path='/' element={<Navigate to="/sign-in" replace />}/> */}
        <Route path='/' element={<Navigate to="/bw-analyser" replace />}/>
        <Route path="/register" element={<Form auth = {auth} />} />
        <Route path="/bw-analyser" element={<BWAnalyzer auth = {auth} />} />
        <Route path="/attach" element={<Attachments auth = {auth} />} />
      </Routes>
    </Router>
  );
};

export default App;
