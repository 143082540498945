import React, { useCallback, useRef } from 'react';
import { useState, useEffect } from 'react';
import classes from './BWAnalyser.module.css';
import Header from '../Header/Header';
import { useNavigate } from 'react-router-dom';
// import arrow from '../../assets/images/arrow2.png'; // Previous arrow
import arrow from '../../assets/images/arrow.png'; // Previous arrow
import apiGatewayService from '../../common/apiGateway.service';
import Attachments from '../Attachments/Attachments';
import Spinner from '../Spinner/Spinner';
import localStorageService from '../../common/localStorage.service';
import Blurb from '../Blurb/Blurb';


const descriptionText = 'Citra Solutions BW Analyser leverages advanced GEN AI to analyse your SAP BW system and generate a comprehensive report to facilitate your transition to a modern data platform.By examining various components such as process chains, DTPs, infoproviders, infoobjects, data volumes, query usage, data loads, data change frequency, disk and memory usage, and transformation logic and complexity, our tool provides a clear pathway to modernise your data infrastructure. Sign up now to register your interest!';

const BWAnalyser  = ({auth}) => {
    const navigate = useNavigate();

    useEffect(() => {
        const user = localStorageService.getObject('user');
        setCogntioUser(user);
        console.log("currentCognito User: ", user);
        if(user!==null){
            setIsLoggedIn(true);
            setCurrButton('extract');
        }
    }, []);


    const [selectedButton, setSelectedButton] = useState(null); 
    const [isDownloadEnabled, setIsDownloadEnabled] = useState(false);
    const [isUploadFiles, setIsUploadFiles] = useState(false);
    const [downloadUrl, setDownloadUrl] = useState('');
    const [isVerified, setIsVerified] = useState(false); //for checkig if the user is valid
    const [loading, setLoading] = useState(false);
    const [cogntioUser, setCogntioUser] = useState(null);
    const [isLoggedIn, setIsLoggedIn] =  useState(false);
    const [currButton, setCurrButton] = useState('register');
    const [textForDisplay, setTextForDisplay] = useState(descriptionText);

    const handleClick = async (event) => {
        if(cogntioUser!==null || event.target.id === 'register') {
            console.log(`Button clicked: ${event.target.textContent}`);
            console.log(event.target.id);
            switch(event.target.id) {
                case 'register':
                    navigate('/sign-up');
                    break;
                case 'extract':
                    setAllOptionFalse();
                    await getExtractFiles();
                    setIsDownloadEnabled(true);
                    // alert("Your request has been processed");
                    break;
                case 'upload':
                    setAllOptionFalse();
                    setIsUploadFiles(true);
                    break
                default:
                    break;
            }
        }
        else {
            alert("Please sign in or sign up to continue");
        }
    };

    const setAllOptionFalse = () =>{
        setIsDownloadEnabled(false);
        setIsUploadFiles(false);
    };

    const getExtractFiles = async ()=>{
        try {
            setLoading(true);
            const userGroup = cogntioUser.signInUserSession.accessToken.payload["cognito:groups"];
            const token = cogntioUser.signInUserSession.idToken.jwtToken;
            const email = cogntioUser.username;
            console.log("token: ", token);
            console.log("email: ", email);
            console.log("userGroup: ", userGroup);
            const response = await apiGatewayService.getBWAnalyzerFiles(token);
            setLoading(false);
            console.log("response: ", response);
            setIsDownloadEnabled(true);
            setDownloadUrl(response.data.url);      
            setTextForDisplay('Your request is being processed. We will notify you shortly.');
          } catch (error) {
            setLoading(false);
            setIsDownloadEnabled(false);
            console.error('Error downloading file:', error);
          }
    };

    const handleLogin = () => {
        navigate('/sign-in');
    };

    const handleSignUp = () => {
        navigate('/sign-up');
    };

    const handleLogOut = () => {
        localStorageService.removeObject('user');
        setIsLoggedIn(false);
        setAllOptionFalse();
        setCogntioUser(null);
        navigate('/sign-in');
        setTimeout(()=>{
        navigate('/bw-analyser');
        },50);
    };


    const handleDownload = () => {

    };

    const getEmailAndToken = ()  => {

    };

    return (
        <div className={classes.MainDiv}>
            <Header />
            <div className={classes.LoginButtonDiv}>
                {isLoggedIn && <button className={classes.LoginButton} onClick={handleLogOut}> LogOut</button>}
                {isLoggedIn && cogntioUser && <label className={classes.UserName}>{`${cogntioUser.signInUserSession.accessToken.payload["cognito:groups"][0]}`}</label>}
                {!isLoggedIn && <button className={classes.LoginButton} onClick={handleSignUp}> SignUp </button>}
                {!isLoggedIn && <button className={classes.LoginButton} onClick={handleLogin}> Login </button>}
            </div>
            <div className={classes.ButtonRow}>
                <button id="register" className={ (currButton==='register') ? classes.SelectButtonPressed : classes.SelectButton} onClick={handleClick}>Register</button>
                {/* <div className="arrow">→</div> */}
                <img className={classes.Arrow} src={arrow} alt="arrow" />
                <button id="extract" className={(currButton==='extract') ? classes.SelectButtonPressed : classes.SelectButton} onClick={handleClick}>Download <br></br> Meta Data <br></br> Extraction Program</button>
                {/* <div className="arrow">→</div> */}
                <img className={classes.Arrow} src={arrow} alt="arrow" />
                <button id="upload" className={(currButton==='upload') ? classes.SelectButtonPressed : classes.SelectButton} onClick={handleClick}>Upload Files</button>
                {/* <div className="arrow">→</div> */}
                <img className={classes.Arrow} src={arrow} alt="arrow" />
                <button id="report" className={(currButton==='report') ? classes.SelectButtonPressed : classes.SelectButton} onClick={handleClick}>Process Report</button>
                {/* <div className="arrow">→</div> */}
                <img className={classes.Arrow} src={arrow} alt="arrow" />
                <button id="consult" className={(currButton==='consult') ? classes.SelectButtonPressed : classes.SelectButton} onClick={handleClick}> Book <br></br> Consultation</button>
            </div>
            {/* <hr className={classes.LineBreak}></hr> */}
            <p className={classes.Description}>
                {textForDisplay}
            </p>
            <div>
                {
                    isUploadFiles && <Attachments
                        setIsUploadFiles={setIsUploadFiles}
                    />
                }
            </div>
            {/* <div className={classes.DownloadButtonDiv}>
            { isDownloadEnabled &&
             <button className = {classes.DownloadButton}>
              <a href={downloadUrl}>
                Download File
              </a>
             </button>
            }
            </div> */}
            {/* <div>
                {isDownloadEnabled &&
                    <Blurb
                        text='Your request is being processed. We will notify you shortly.'
                    />
                }
            </div> */}
            <div>
                {loading && <Spinner />}
            </div>
        </div>
    );
}

export default BWAnalyser ;