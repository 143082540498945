import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { validateField } from '../../common/validate'; 
import './FormStyles.css'; // Update the path according to your file structure
import classes from './Form.module.css';
import Section from '../Section/Section';
import apiGatewayService from '../../common/apiGateway.service';
import data from './ContactForm.json';
import Header from '../Header/Header';
import localStorageService from '../../common/localStorage.service';
import { useNavigate } from 'react-router-dom';

const Form = ({auth}) => {
    const navigate = useNavigate();
    const [formConfig, setFormConfig] = useState({ sections: [] });
    const [dropdownOptions, setDropdownOptions] = useState({});
    const [fieldValues, setFieldValues] = useState({});
    const [activeSectionIndex, setActiveSectionIndex] = useState(0);
    const [formId, setFormId] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [redirectURL, setRedirectURL] = useState('');
    const [errors, setErrors] = useState({});
    const [cognitoUser, setCognitoUser] = useState(null);

    
    // Style customization for react-select
    const customStyles = {
        control: (styles) => ({ ...styles, backgroundColor: 'white' }),
        option: (styles, { isFocused }) => ({
            ...styles,
            backgroundColor: isFocused ? 'lightgray' : 'white',
            color: 'black',
        }),
    };

    useEffect(() => {
        setCognitoUser(localStorageService.getObject('user'));
        const fetchData = async () => {
            try {
                const user = localStorageService.getObject('user');
                const token = user.signInUserSession.idToken.jwtToken;
                const email = user.username;
                const formIdFromUrl = window.location.hash.substring(1);
                console.log('formIdFromUrl:  ', formIdFromUrl);
                setFormId(formIdFromUrl);
                // const apiUrl = `https://zspe1uaat0.execute-api.ap-southeast-2.amazonaws.com/form/${formIdFromUrl}`;
                // const response = await axios.get(apiUrl);

                const response = await apiGatewayService.getFormConfiguration(token,formIdFromUrl);
                setFormConfig(response.data);
                initializeFieldValues(response.data.sections);
                await fetchDropdownData(response.data.sections);

                // const response = data;
                // setFormConfig(response);
                // initializeFieldValues(response.sections);
                // await fetchDropdownData(response.sections);
            } catch (error) {
                console.error('Failed to fetch form config:', error);
            }
        };
        fetchData();
    }, []);

    const initializeFieldValues = (sections) => {
        const initialFieldValues = {};
        sections.forEach((section, sectionIndex) => {
            section.fields.forEach(field => {
                const fieldKey = `${sectionIndex}-${field.name}`;
                initialFieldValues[fieldKey] = field.api?.default || '';
            });
        });
        setFieldValues(initialFieldValues);
    };

    const Navigation = ({ sections, onSectionClick, activeSectionIndex }) => {
        return (
            <div className={classes.Navigation}>
                {sections.map((section, index) => (
                    <button
                        key={index}
                        // className={`tab-button ${index === activeSectionIndex ? 'active' : ''}`}
                        className={classes.TabButton}
                        onClick={() => onSectionClick(index)}
                    >
                        {section.title}
                    </button>
                ))}
            </div>
        );
    };



    const onSectionClick = (index) => {
        setActiveSectionIndex(index);

        // Scroll to the section
        const sectionElement = document.getElementById(`section-${index}`);
        if (sectionElement) {
            sectionElement.scrollIntoView({ behavior: 'smooth', block: 'start' });

            // Set focus to the first input or select element within the section
            const firstInput = sectionElement.querySelector('input, select');
            if (firstInput) {
                firstInput.focus();
            }
        }
    };

    const resetDropdown = (sectionIndex, fieldName) => {
        const fieldKey = `${sectionIndex}-${fieldName}`;
        const fieldConfig = formConfig.sections[sectionIndex].fields.find(field => field.name === fieldName);
    
        if (fieldConfig && fieldConfig.api.default) {
            // Set field value to default if specified
            setFieldValues(prevValues => ({
                ...prevValues,
                [fieldKey]: fieldConfig.api.default
            }));
        } else {
            // Otherwise, reset the dropdown options and set value to empty
            setDropdownOptions(prevOptions => ({
                ...prevOptions,
                [fieldKey]: [{label: '', value: ''}]  // Clear the options
            }));
            setFieldValues(prevValues => ({
                ...prevValues,
                [fieldKey]: ''
            }));
        }
    };

    
    const fetchSpecificDropdownData = async (sections, specificFieldKey = null, newValue) => {
        try {
            for (const [sectionIndex, section] of sections.entries()) {
                for (const field of section.fields) {
                    const fieldKey = `${sectionIndex}-${field.name}`;
                    if (field.type === 'dropdown' || field.type === 'selection') {
                        if (!specificFieldKey || specificFieldKey === fieldKey) {
                            let apiUrl = field.api.url;
                            console.log("apiUrl: ", apiUrl);
                            if (field.api.dependencies) {
                                const filters = field.api.dependencies.map(dep => {
                                    const depSection = sections.find(sec => sec.title === dep.sectionName);
                                    const depSectionIndex = sections.indexOf(depSection);
                                    const depFieldKey = `${depSectionIndex}-${dep.fieldName}`;
                                    const selectedOption = dropdownOptions[depFieldKey]?.find(option => option.value === newValue);
                                    let filterValue = '';
                                    if (dep.filterValue === 'label' && selectedOption) {
                                        filterValue = encodeURIComponent(selectedOption.srcLabel);
                                    } else if (dep.filterValue === 'value') {
                                        filterValue = encodeURIComponent(newValue);
                                    }
                                    return `${dep.filterField}='${filterValue}'`;
                                }).filter(f => f.includes("'")).join(',');
                                apiUrl += `(${filters})`;
                            }
    
                            // const response = await axios.get(apiUrl);
                            const response = await apiGatewayService.getDropDownData(apiUrl);
                            const options = response.data.map(item => ({
                                label: field.api.displayPattern.replace('{label}', item[field.api.label]).replace('{value}', item[field.api.value]),
                                value: item[field.api.value],
                                additionalText: item.VALID_VALUE_ADDITIONAL_TEXT || "",
                                srcLabel: item[field.api.label]
                            }));
    
                            if (!field.api.default) {
                                const placeholder = field.placeholdertext || ""; //Select an option
                                options.unshift({ label: placeholder, value: "" });
                            }
    
                            setDropdownOptions(prevOptions => ({
                                ...prevOptions,
                                [fieldKey]: options
                            }));
    
                            if (field.api.default) {
                                setFieldValues(prev => ({
                                    ...prev,
                                    [fieldKey]: field.api.default
                                }));
                            }
                        }
                    }
                }
            }
        } catch (error) {
            console.error('Failed to fetch dropdown options:', error);
        }
    };
    
    const adjustUrlWithDependencies = (url, dependencies, sectionIndex) => {
        if (!dependencies) return url;
        let filters = '';
        Object.entries(dependencies).forEach(([depField, filterName], index) => {
            const depFieldKey = `${sectionIndex}-${depField}`;
            const depValue = fieldValues[depFieldKey];
            if (depValue) {
                filters += `${index > 0 ? ',' : ''}${filterName}='${encodeURIComponent(depValue)}'`;
            }
        });
    
        return filters ? `${url}(${filters})` : url;
    };
    

    // // Ensure to call this function whenever dependencies change
    // useEffect(() => {
    //     fetchDropdownData(formConfig.sections);
    // }, [fieldValues]); // You might list specific fields if needed to minimize unnecessary fetches

    const fetchDropdownData = async (sections) => {
        try {
            for (const [sectionIndex, section] of sections.entries()) {
                for (const field of section.fields) {
                    if (['dropdown', 'selection'].includes(field.type) && field.api) {
                        const { url, label, value, displayPattern = "{label} - {value}", default: defaultValue, dependencies } = field.api;
                        const fieldKey = `${sectionIndex}-${field.name}`;

                        let baseUrl = url;
                        console.log("CHECK BASE URL: ", baseUrl);

                        // Handle dependencies to format URL as required
                        if (dependencies) {
                            const dependencyFilters = Object.entries(dependencies).map(([key, placeholder]) => {
                                const dependentFieldValue = fieldValues[`${sectionIndex}-${key}`]; // Assume key is the field name of dependency
                                return `${placeholder}='${encodeURIComponent(dependentFieldValue)}'`;
                            }).join(',');

                            baseUrl += `(${dependencyFilters})`; // Append formatted filters directly to the URL
                        }
                        console.log(baseUrl);  // Debugging to see what is being stored

                        // const response = await axios.get(baseUrl);
                        const user = localStorageService.getObject('user');
                        const response = await apiGatewayService.getDropDownData(user.signInUserSession.idToken.jwtToken,baseUrl);
                        console.log("CHECK BASE URL Res: ", response);
                        // console.log("Get Drop Down Response: ", response);
                        // console.log("Get Drop Down Response Section: ", section); 
                        // console.log("Get Drop Down Response label: ", label); 
                        // console.log("Get Drop Down Response value: ", value); 
                        const options = response.data.map(item => {
                            // Check if we're dealing with simple string array ('self' option)
                            if (label === 'self' && value === 'self') {
                                return {
                                    label: item,  // Use item directly for both label and value
                                    value: item,
                                    additionalText: item.VALID_VALUE_ADDITIONAL_TEXT || ""  // Assume additional text might be directly in the item
                                };
                            } else {
                                return {
                                    label: displayPattern.replace('{label}', item[label]).replace('{value}', item[value]),
                                    value: item[value],
                                    additionalText: item.VALID_VALUE_ADDITIONAL_TEXT || "",
                                    srcLabel: item[label]
                                };
                            }
                        });

                        console.log(options);  // Debugging to see what is being stored

                        if (!defaultValue) {
                            // Use placeholder text from config or default to a generic placeholder
                            const placeholder = field.placeholdertext || ""; //Select an option
                            options.unshift({ label: placeholder, value: "" });  // Prepend the placeholder option
                        }

                        setDropdownOptions(prevOptions => ({
                            ...prevOptions,
                            [fieldKey]: options
                        }));

                        setFieldValues(prev => ({
                            ...prev,
                            [fieldKey]: defaultValue || ''
                        }));
                    }
                }
            }
        } catch (error) {
            console.error('Failed to fetch dropdown options:', error);
        }
    };

    const handleInputChange = async (value, sectionIndex, fieldName) => {
        const fieldKey = `${sectionIndex}-${fieldName}`;
        const newFieldValues = {
            ...fieldValues,
            [fieldKey]: value
        };
        setFieldValues(newFieldValues);
        let newErrors = { ...errors };
    
        // Retrieve the field config to determine if validation is needed
        const fieldConfig = formConfig.sections[sectionIndex].fields.find(field => field.name === fieldName);
    
        if (fieldConfig.validation && !validateField(fieldName, value, fieldConfig.validation)) {
            newErrors[fieldKey] = `Invalid ${fieldConfig.label.toLowerCase()}`;
        } else {
            delete newErrors[fieldKey];
        }
    
        // Check if the changed field has dependents that need to be updated
        for (const [secIndex, section] of formConfig.sections.entries()) {
            for (const field of section.fields) {
                if (field.api && field.api.dependencies) {
                    for (const dep of field.api.dependencies) {
                        if (dep.fieldName === fieldName && dep.sectionName === formConfig.sections[sectionIndex].title) {
                            // Fetch data for the dependent dropdown
                            const dependentFieldKey = `${secIndex}-${field.name}`;
                            console.log("dependentFieldKey: ", fieldValues[dependentFieldKey]);
                            // Fetch new data only if the current value is available and different from the default
                            if (value !== (field.api.default || '')) {
                                await fetchSpecificDropdownData(formConfig.sections, dependentFieldKey, value);
                            } else {
                                resetDropdown(secIndex, field.name);
                            }
                        }
                    }
                }
            }
        }
    
        setErrors(newErrors);
    };
    
    
    // Handles changes from native inputs (text, textarea, native select)
    const handleNativeInputChange = async (event, sectionIndex) => {
        const { name, value } = event.target;
        await handleInputChange(value, sectionIndex, name);
    };
    

    // Handles changes from React-Select
    const handleSelectChange = async (selectedOption, sectionIndex, fieldName) => {
        // React-Select gives us the selected option object or null if cleared
        const value = selectedOption ? selectedOption.value : '';
        await handleInputChange(value, sectionIndex, fieldName);
    };
    
    const handleSubmit = async (event) => {
        try {
            event.preventDefault();  // Prevent the default form submission behavior
    
            // Extract the form data from the event
            const formData = new FormData(event.target);
            const formObj = Object.fromEntries(formData.entries());
    
            // Retrieve the submit API from the form configuration
            const submitUrl = formConfig.submitApi;
    
            // Check if the submit URL is available
            if (!submitUrl) {
                throw new Error('Submit API URL is not defined in the form configuration.');
            }
            
            console.log("This is the form object to submit: ", formObj);

            //uncomment this later when you actually have to submit the data    
            // // Use Axios to post the form data to the configured submit API
            // await axios.post(submitUrl, formObj);
            const user = localStorageService.getObject('user');
            await apiGatewayService.postFormData(user.signInUserSession.idToken.jwtToken, submitUrl, formObj);
            navigate('/bw-analyser')
    
            // Check for redirect URL in the configuration
            const redirectUrl = formConfig.redirectURL;
            if (redirectUrl) {
                // Redirect if URL is provided
                setRedirectURL(formConfig.redirectURL); // Store redirect URL
                setShowModal(true); // Show modal on success
            } else {
                // Reset the form if no redirect URL is specified
                alert('Form submitted successfully');
                resetForm();
            }
        } catch (error) {
            console.error('Error submitting form:', error);
            alert('Submission failed: ' + error.message);
        }
    };    
    
    const resetForm = () => {
        // Reset field values to initial (empty or default) state
        const initialFieldValues = {};
        formConfig.sections.forEach((section, sectionIndex) => {
            section.fields.forEach(field => {
                const fieldKey = `${sectionIndex}-${field.name}`;
                initialFieldValues[fieldKey] = field.api?.default || '';
            });
        });
        setFieldValues(initialFieldValues);
        // Optionally reset other state like errors, etc.
        setErrors({});
    };
    
    

    const determineVisibility = (field, sectionIndex) => {
        if (!field.dependant) {
            return true; // Show the field unless explicitly hidden
        }
        const dependencyFieldKey = `${sectionIndex}-${field.dependant.field}`;
        const dependencyMet = fieldValues[dependencyFieldKey];
        return dependencyMet && dependencyMet.length > 0;
    };

    return (
        <div>
            <Header />
            <Navigation className={classes.Navigation} sections={formConfig.sections} onSectionClick={onSectionClick} activeSectionIndex={activeSectionIndex} />
            <form onSubmit={handleSubmit} className={classes.FormContainer}>
                {formConfig.sections.map((section, sectionIndex) => (
                    <Section  
                    section = {section}
                    formConfig={formConfig}
                    errors={errors}
                    setFieldValues={setFieldValues}
                    sectionIndex={sectionIndex}
                    dropdownOptions={dropdownOptions}
                    fieldValues={fieldValues}
                    handleNativeInputChange = {handleNativeInputChange}
                    determineVisibility = {determineVisibility}
                    handleSelectChange = {handleSelectChange}
                    />
                ))}
                <button type="submit" className={classes.SubmitButton}>Submit</button>
            </form>
            <Modal
                isOpen={showModal}
                onRequestClose={() => setShowModal(false)}
                contentLabel="Submission Success"
            >
                <h2>Form Submitted Successfully</h2>
                <button onClick={() => {
                    window.location.href = redirectURL;
                }}>OK</button>
            </Modal>
        </div>
    );
};
export default Form;
