import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import classes from './VerifyCode.module.css';
import Header from '../Header/Header';
import Error from '../Error/Error';
import Spinner from '../Spinner/Spinner';
// import logoBlack from  '../../assets/images/BRS_LOGO_FTM_BLACK.png';
import logoBlack from  '../../assets/images/citra-header-logo.png';
import localStorageService from '../../common/localStorage.service';

const VerifyCode = ({ auth }) => {
  const navigate = useNavigate();
  const [code, setCode] = useState('');
  const [error, setError] = useState('');
  const [attemptsLeft, setAtemptsLeft] = useState(2);
  const [loading, setLoading] = useState(false); // Add loading state
  const [clicked, setClicked] = useState(false);
  const [currState, setCurrState] = useState('');

  // In this useEffect we only check for email because we do not have the toke yet
  // token is received after code is verified
  useEffect(() => {
    setCurrState(localStorageService.getObject('signIn'));
    const email = auth.getUserEmail();
    console.log(email);
    if(email!==null){
        console.log('stay on the component.')
    }
    else{
        console.log("Error in Authentication going back to Sign in page");
        console.log(error);
        navigate('/sign-in');
    }
}, []);


  useEffect(() => {
    let debounceTimer;
    if (clicked) {
      // Set a timeout to reset the clicked state after 1 second
      debounceTimer = setTimeout(() => {
        setClicked(false);
      }, 2000); // Adjust the debounce time as needed (e.g., 1000ms = 1 second)
    }

    return () => {
      // Clear the timeout when the component unmounts or when clicked changes
      clearTimeout(debounceTimer);
    };
  }, [clicked]);

  const handleVerifyCode = async () => {
    if (!clicked) {
      console.log('Button clicked!');
      setClicked(true);
      try {
        setLoading(true);
        const response = await auth.answerCustomChallenge(code);
        console.log(response);
        if (response) {
          localStorageService.setObject('user', auth.cognitoUser);
          if(currState === 'signIn'){
            navigate('/bw-analyser');
          }
          else{
          // navigate('/registration-form');
          navigate('/register');
          }
        }
        else {
          throw new Error();
        }
      } catch (error) {
        setLoading(false);
        if (attemptsLeft === 0) {
          navigate('/sign-in');
        }
        setAtemptsLeft(attemptsLeft - 1);
        // If verification fails, display error message
        setError(`Invalid code. Please try again. ${attemptsLeft} attempts left`);
        console.error('Error verifying code:', error);
      }
    } else {
      console.log('Button is clicked already. Waiting for time out.')
    }
  };

  return (
    <div className={classes.MainBackground}>
    <div className={classes.VerifyCode}>
      <Header />
      <div className={classes.Border}>
      <div className={classes.LogoDiv}>
                <img className={classes.Logo} src={logoBlack} alt="ao-logo"/>
      </div>
      <hr></hr>
        <h3 className={classes.Header}>Please check your email for code</h3>
        <input
          type="text"
          placeholder="Enter 6-digit code"
          value={code}
          onChange={(e) => setCode(e.target.value)}
          className={classes.InputClass}
          maxLength="6"
        />
        <button className={classes.Button} onClick={handleVerifyCode}>Submit</button>
      </div>
      {loading && <Spinner/>}
      <Error error = {error}></Error>
    </div>
    </div>
  );
};

export default VerifyCode;
