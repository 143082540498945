// Steps to deploy this app using aws cli:
// 1, Configure an aws profile named citra-dev
// 2, Go to root director on this folder on terminal
// 3. npm run build
// 4, aws --profile citra-bwa s3 sync build/ s3://app.citras.bw.analyser

// export const AWS_API = 'https://t879cxzso7.execute-api.ap-southeast-2.amazonaws.com';
// export const region = 'ap-southeast-2';
// export const userPoolId = 'ap-southeast-2_rDOq3QMJ4';
// export const userPoolClientId = '774uo6f59ul308403545oeutt5';


export const AWS_API = 'https://j16am9chll.execute-api.ap-southeast-2.amazonaws.com';
export const region = 'ap-southeast-2';
export const userPoolId = 'ap-southeast-2_6kOwm7nAZ';
export const userPoolClientId = '7fcbuf3tnq69ek4bet1lv885m7';